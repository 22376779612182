<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Add Voucher
        <div class="card-header-actions">
          <a class="card-header-action" href="digital" rel="noreferrer noopener">
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
            <div class="sk-three-bounce">
                <div class="sk-child sk-bounce1"></div>
                <div class="sk-child sk-bounce2"></div>
                <div class="sk-child sk-bounce3"></div>
            </div>
        </div>
        <b-row>
          <b-col sm="12">
            <!--<h6>Simple Form</h6>-->
            <b-form v-on:submit.prevent="onSubmit" novalidate action="https://vuejs.org/">
              <b-form-group id="user_type"
                                label="User Type"
                                label-for="user_type">
                <b-form-select id="user_type"
                  v-model="form.user_type"
                  aria-describedby="userTypeFeedback"
                  :plain="true"
                  :options="[
                    'All',
                    'New User',
                    'Register At',
                    'First Time Buyer',
                    'Last Time Buy'
                  ]">
                </b-form-select>
              </b-form-group>
              <b-form-group id="body"
                            label="Syarat dan Ketentuan Voucher"
                            label-for="body">
                <quill-editor id="body"
                            style="margin-bottom:5px"
                            v-model="form.tnc"
                            autocomplete='family-name'>
                </quill-editor>
              </b-form-group>
              <b-row
                v-if="form.user_type === 'Register At'"
              >
                <b-col sm>
                  <b-form-group
                    label="Register Start :"
                    label-for="daterange"
                  >
                    <b-input-group
                    >
                      <date-picker
                        v-model="register_start"
                        format="YYYY-MM-DD"
                        type="datetime"
                        lang="en"
                        value-type="format"
                        placeholder="YYYY-MM-DD"
                      ></date-picker>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col sm>
                  <b-form-group
                    label="Register End (Optional) :"
                    label-for="daterange"
                  >
                    <b-input-group
                    >
                      <date-picker
                        v-model="register_end"
                        format="YYYY-MM-DD"
                        type="datetime"
                        lang="en"
                        value-type="format"
                        placeholder="YYYY-MM-DD"
                      ></date-picker>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group id="days"
                            v-if="form.user_type === 'Last Time Buy'"
                            label="Days"
                            label-for="day">
                <b-form-input id="day"
                              type="number"
                              v-model.lazy.trim="$v.form.day.$model"
                              :state="chkState('day')"
                              aria-describedby="dayFeedback"
                              placeholder="0"
                              autocomplete='given-name'
                              autofocus />
                <b-form-invalid-feedback id="dayFeedback" >
                  <span v-if="!$v.form.day.numeric">- Jumlah hari tidak boleh kurang dari 0!</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="redeem_type"
                                label="Redeem Type"
                                label-for="redeem_type">
                    <b-form-select id="redeem_type"
                      v-model="form.redeem_type"
                      aria-describedby="typeFeedback"
                      :plain="true"
                      :options="[{value: 'single', text: 'Single Nominal Redeem'},
                                {value: 'multiple', text: 'Multiple Nominal Redeem'}]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    class="mb-3"
                    label="Organization"
                    label-for="so"
                  >
                    <b-form-select id="so" v-model="organization" :options="organizationOptions" @change="getReseller"></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group
                class="mb-3"
                v-if="organization !== ''"
                label="Voucher Reseller Admin"
                label-for="so"
              >
                <b-form-select id="so" v-model="reseller" :options="resellerOptions" @change="getReseller"></b-form-select>
              </b-form-group>
              <!-- <div>
                <b-form-group
                  class="mb-3"
                  label="Voucher Period Start From and End To :"
                  label-for="daterange"
                >
                  <b-input-group
                  >
                    <b-input-group-prepend is-text>
                      <i class="fa fa-calendar"></i>
                    </b-input-group-prepend>
                    <date-range-picker id="daterange" :start="startDate" :end="endDate" @picker="changePicker"></date-range-picker>
                  </b-input-group>
                </b-form-group>
              </div> -->
              <b-row>
                <b-col sm="6">
                  <b-form-group id="nameInputGroup1"
                                label="Voucher Name"
                                label-for="name">
                    <b-form-input id="name"
                                  type="text"
                                  v-model.lazy.trim="$v.form.name.$model"
                                  :state="chkState('name')"
                                  aria-describedby="nameFeedback"
                                  placeholder="Please Enter Voucher Name"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="nameFeedback">
                      <span v-if="!this.$v.form.name.required"> Redeem Type tidak boleh kosong.</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                 <b-col sm="6">
                  <b-form-group id="amountInputGroup1"
                                label="Voucher Amount"
                                label-for="amount">
                    <b-form-input id="amount"
                                  type="number"
                                  v-model.lazy.trim="$v.form.amount.$model"
                                  :state="chkState('amount')"
                                  aria-describedby="amountFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="amountFeedback" >
                      <span v-if="!$v.form.amount.required">- Amount tidak boleh kosong!</span>
                      <span v-if="!$v.form.amount.numeric">- Amount tidak boleh kurang dari 0!</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="max_buy"
                                label="Maximum Buy"
                                label-for="max_buy">
                    <b-form-input id="max_buy"
                                  type="number"
                                  v-model.lazy.trim="$v.form.max_buy.$model"
                                  :state="chkState('max_buy')"
                                  aria-describedby="maxBuyFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="maxBuyFeedback" >
                      <span v-if="!$v.form.max_buy.required">- Maximum Buy tidak boleh kosong!</span>
                      <span v-if="!$v.form.max_buy.numeric">- Maximum Buy tidak boleh kurang dari 0!</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                 <b-col sm="6">
                  <b-form-group id="price"
                                label="Voucher Price"
                                label-for="price">
                    <b-form-input id="price"
                                  type="number"
                                  v-model.lazy.trim="$v.form.price.$model"
                                  :state="chkState('price')"
                                  aria-describedby="priceFeedback"
                                  placeholder="0"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="priceFeedback" >
                      <span v-if="!$v.form.price.required">- Voucher Price tidak boleh kosong!</span>
                      <span v-if="!$v.form.price.numeric">- Voucher Price tidak boleh kurang dari 0!</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    id="promotion_type"
                    label="Price Promotion Type (Optional)"
                    label-for="promotion_type"
                  >
                    <b-form-select
                      id="promotion_type"
                      v-model="form.promotion_type"
                      :plain="true"
                      @change="resetAmount"
                      :options="[
                        { value: 'no_promotion', text: 'No Promotion' },
                        { value: 'fixed', text: 'Fixed Price' },
                        { value: 'percent', text: 'Discount' },
                      ]"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                 <b-col sm="6">
                  <b-form-group id="promotion_amount"
                                label="Nilai Promosi (Optional)"
                                label-for="promotion_amount">
                    <b-form-input id="promotion_amount"
                                  type="number"
                                  v-model.lazy.trim="$v.form.promotion_amount.$model"
                                  :state="chkState('promotion_amount')"
                                  aria-describedby="promotionAmountFeedback"
                                  placeholder="0"
                                  :disabled="form.promotion_type === 'no_promotion' ? true : false"
                                  autocomplete='given-name'
                                  autofocus />
                    <b-form-invalid-feedback id="promotionAmountFeedback" >
                      <span v-if="!$v.form.promotion_amount.decimal">- Nilai Promosi tidak boleh kurang dari 0!</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6">
                  <b-form-group id="expire_type"
                                label="Voucher Expired Time Type"
                                label-for="expire_type">
                    <b-form-select id="expired_type"
                      v-model="form.expired_type"
                      aria-describedby="expiredtypeFeedback"
                      :plain="true"
                      :options="[
                        {value: 'days', text: 'Days'},
                        {value: 'months', text: 'Months'}
                      ]">
                    </b-form-select>
                  </b-form-group>
                </b-col>
                 <b-col sm="6">
                  <div id="wallet">
                    <label for="number">Voucher Will Expire On</label>
                    <div class="input-group mb-3">
                      <input type="number" class="form-control" v-model="date" placeholder="Wallet Expire">
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">{{ this.form.expired_type == '' ? 'minutes' : this.form.expired_type }}</span>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-form-group id="userNameInputGroup3"
                            label="Image"
                            label-for="userName">
                <b-form-file id="selectedImage"
                              :plain="true"
                              accept="image/*"
                              aria-describedby="imageFeedback"
                              v-model="form.selectedImage"
                              @change="onFileSelected">
                </b-form-file>
              </b-form-group>
              <b-form-checkbox
                id="checkbox-1"
                v-model="status"
                name="checkbox-1"
                :value="true"
              >
                Publish
              </b-form-checkbox>
              <b-button type="submit" variant="primary">
                Submit
              </b-button>
            </b-form>
            <br/>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, helpers , numeric} from "vuelidate/lib/validators"
import dotenv from 'dotenv'
import moment from 'moment'
import axios from 'axios'

dotenv.config()

// const end = helpers('end', this.form.end_to <= this.form.start_from)
const decimal = helpers.regex('decimal', /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/)

export default {
  name: "generate-digital",
  data() {
    return {
      register_start: moment().format('YYYY-MM-DD'),
      register_end: '',
      startDate: '',
      endDate: '',
      form: {
        user_type: 'All',
        redeem_type: 'single',
        name: '',
        amount: '',
        max_buy: '',
        price: '',
        promotion_type: '',
        promotion_amount: '',
        expired_type: 'days',
        selectedImage: '',
        day: '',
        tnc: ''
      },
      date: '',
      status: false,
      organization: '',
      organizationOptions: [],
      reseller: '',
      resellerOptions: [],
      isLoading: false,
    }
  },
  computed: {
    formStr() { return JSON.stringify(this.form, null, 4) },
    isValid() { return !this.$v.form.$anyError },
    isDirty() { return this.$v.form.$anyDirty },
    invCheck() { return 'You must accept before submitting' },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      day: {
        numeric
      },
      name: {
        required,
      },
      amount: {
        required,
        numeric
      },
      max_buy: {
        required,
        numeric
      },
      price: {
        required,
        numeric
      },
      promotion_amount: {
        decimal
      }
    }
  },
  created() {
    console.log(this.$quillStyle);
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`
    axios.get(process.env.VUE_APP_SECRET + 'admin/v2/command/organization-command?reseller=true').then((response) => {
      let base = [
        {text: 'Pilih Organisasi', value: ''}
      ]

      this.organizationOptions = response.data.data.concat(base)
    })
  },
  methods: {
    resetAmount() {
      if (this.form.promotion_type === 'no_promotion') {
        this.form.promotion_amount = ''
      }
    },
    getReseller() {
      axios.get(process.env.VUE_APP_SECRET + `admin/v2/command/user-reseller?hashedId=${this.organization}`).then((response) => {
        this.organizationOptions = response.data.data
        this.reseller = response.data.data[0].value
        this.resellerOptions = response.data.data
      })
    },
    onFileSelected(event) {
      this.form.selectedImage = event.target.files[0];
    },
    changePicker (value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isLoading = true
        const formData =  new FormData();

        if (this.form.selectedImage == '') {
          formData.append('image', '')
        }else{
          formData.append('image', this.form.selectedImage, this.form.selectedImage.name)
        }
        formData.append("user_type", this.form.user_type);
        formData.append("redeem_type", this.form.redeem_type);
        formData.append("name", this.form.name);
        formData.append("amount", this.form.amount);
        formData.append("max_buy", this.form.max_buy);
        formData.append("price", this.form.price);
        formData.append("promotion_type", this.form.promotion_type === 'no_promotion' ? '' : this.form.promotion_type);
        formData.append("promotion_amount", this.form.promotion_type === 'no_promotion' ? '' : this.form.promotion_amount);
        formData.append("expired_type", this.form.expired_type);
        formData.append("wallet_expire_time", this.date);
        formData.append("register_start", this.register_start)
        formData.append("register_end", this.register_end)
        formData.append("day", this.form.day)
        formData.append("admin_id", this.reseller);
        formData.append("tnc", this.form.tnc)
        if (this.status !== false) {
          formData.append("is_visible", true);
        }

        this.$http.post(`digital`, formData)
        .then(() => {
          this.isLoading  = false
          this.$router.push("/digital");
          this.$toasted.success('Voucher Digital successfully created!')
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            if(error.response.data.meta.code == 422) {
              this.$toasted.error('Please fill out the form that must be required')
            }else if(error.response.data.meta.code == 400){
              this.$swal.fire(
                'Failed!',
                error.response.data.meta.message,
                'error'
              )
            }
          }
        })
      }
    },
    chkState(val) {
      const field = this.$v.form[val]
      return !field.$dirty || !field.$invalid
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus()
          return true
        }
        if (component.$refs.check) {
          component.$refs.check.focus()
          return true
        }
      }
      let focused = false
      component.$children.some((child) => {
        focused = this.findFirstError(child)
        return focused
      })

      return focused
    },
    validate() {
      this.$v.$touch()
      this.$nextTick(() => this.findFirstError())
      return this.isValid
    }
  },
}
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}


#dateFeedback {
  color: #f86c6b !important;
  font-size: 100%;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
